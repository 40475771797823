import React, { ChangeEvent, FormEvent, useState } from "react";

interface Props {
  url: string;
  query?: string;
}

export default function SearchForm(props: Props) {
  const { url } = props;

  const [query, setQuery] = useState<string>(props.query || "");

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);
  };

  const handleSubmit = (evt: FormEvent) => {
    if (!query.trim()) {
      evt.preventDefault();
    }
  };

  return (
    <form
      action={url}
      className="search-form"
      method="GET"
      onSubmit={handleSubmit}>
      <input
        aria-label="Søketekst"
        type="text"
        name="q"
        onChange={handleChange}
        placeholder="Søk i alt innhold..."
        value={query}
      />
      <button type="submit">Søk</button>
    </form>
  );
}
