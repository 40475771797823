import React, { useState } from "react";

interface Props {
  page: NavItem;
  currentPage?: number;
}

export default function Subnav(props: Props) {
  const { page, currentPage } = props;

  const [expanded, setExpanded] = useState(false);

  const classNames = ["subnav"];
  if (expanded) {
    classNames.push("expanded");
  }
  if (page.uniqueName) {
    classNames.push(`vertical-${page.uniqueName}`);
  }

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <nav className={classNames.join(" ")}>
      <h1>
        <button onClick={toggleExpand}>{page.name}</button>
      </h1>
      <ul>
        {page.pages.map((p) => (
          <li key={p.id}>
            <a className={p.id == currentPage ? "current" : ""} href={p.url}>
              {p.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
