import React, { MouseEvent, RefObject, createRef, useState } from "react";

interface Input {
  id: number;
  ref: RefObject<HTMLInputElement>;
}

interface Props {
  label: string;
  name: string;
  description?: string;
}

export default function FileUploads(props: Props) {
  const { label, name, description } = props;

  const [id, setId] = useState(1);

  const [inputs, setInputs] = useState([
    { id: id, ref: createRef<HTMLInputElement>() }
  ]);

  const createInput = () => {
    const nextId = id + 1;
    setId(nextId);
    return { id: nextId, ref: createRef<HTMLInputElement>() };
  };

  const hasFile = (input: Input) => {
    return input.ref.current && input.ref.current.value;
  };

  const handleChange = () => {
    if (inputs.filter((i) => !hasFile(i)).length < 1) {
      setInputs([...inputs, createInput()]);
    }
  };

  const handleRemove = (input: Input) => (evt: MouseEvent) => {
    evt.preventDefault();
    setInputs(inputs.filter((i) => i.id !== input.id));
  };

  return (
    <div className="field file-uploads">
      <label>{label}</label>
      {description && <p className="description">{description}</p>}
      {inputs.map((i) => (
        <div className="file" key={i.id}>
          <input
            required={i === inputs[0]}
            type="file"
            ref={i.ref}
            name={name}
            onChange={handleChange}
          />
          {hasFile(i) && <button onClick={handleRemove(i)}>Fjern</button>}
        </div>
      ))}
    </div>
  );
}
