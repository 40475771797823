import React from "react";

interface Props {
  page: NavItem;
  current: boolean;
}

export default function TopNavItem(props: Props) {
  const { page, current } = props;

  const classNames = [];
  if (page.uniqueName) {
    classNames.push(`vertical-${page.uniqueName}`);
  }
  if (current) {
    classNames.push("current");
  }

  const url =
    page.template == "vertical" && page.pages.length > 0
      ? page.pages[0].url
      : page.url;

  return (
    <li>
      <a href={url} className={classNames.join(" ")}>
        {page.name}
      </a>
    </li>
  );
}
