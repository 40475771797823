import React, { useState } from "react";
import Logo from "./Logo";
import TopNav, { topNavFilter } from "./Header/TopNav";
import Menu, { Props as MenuProps } from "./Header/Menu";

interface Props extends MenuProps {
  currentRoot?: number;
}

export default function Header(props: Props) {
  const { currentRoot, pages } = props;

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  const className = showMenu ? "show-menu" : "";

  return (
    <header className={`top-header ${className}`}>
      <div className="inner">
        <Logo />
        <TopNav currentRoot={currentRoot} pages={topNavFilter(pages)} />
        <button
          aria-expanded={showMenu}
          aria-controls="main-menu"
          className="main-menu-toggle"
          onClick={toggleMenu}>
          Meny
        </button>
        {showMenu && <Menu {...props} />}
      </div>
    </header>
  );
}
