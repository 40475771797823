import React from "react";
import TopNavItem from "./TopNavItem";

interface Props {
  currentRoot?: number;
  pages: NavItem[];
}

export function topNavFilter(pages: NavItem[]) {
  return pages.filter((p) => p.uniqueName && p.uniqueName !== "kritikerlaget");
}

export default function TopNav(props: Props) {
  const { currentRoot, pages } = props;

  return (
    <nav className="top-nav">
      <ul>
        {pages.map((p) => (
          <TopNavItem key={p.id} page={p} current={p.id == currentRoot} />
        ))}
      </ul>
    </nav>
  );
}
