import React from "react";
import SearchForm from "../SearchForm";
import Subnav from "../Subnav";

export interface Props {
  currentPage?: number;
  membership: NavItem;
  pages: NavItem[];
  searchQuery: string;
  searchUrl: string;
}

export default function Menu(props: Props) {
  const { pages, currentPage, searchQuery, searchUrl, membership } = props;

  return (
    <div id="main-menu" className="main-menu">
      <SearchForm query={searchQuery} url={searchUrl} />
      {pages.map((p) => (
        <Subnav key={p.id} page={p} currentPage={currentPage} />
      ))}
      {membership && (
        <div className="buttons">
          <a className="membership button primary" href={membership.url}>
            Bli medlem!
          </a>
        </div>
      )}
    </div>
  );
}
